import { getStyleByScenario as _getStyleByScenario } from '@/js/helpers/scenario.js';
import generalMixin from '@/js/mixins/general';
import { findFieldByIdFromApps } from '@component-library/business-logic/app';
import { TargetType } from '@component-library/business-logic/mapping/styling-rule';
import { DrawingType } from '@component-library/gather';
import _cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';
import { getBasemapApis } from '../business-logic/basemap';
import { checkIsValidLatLng } from '../business-logic/coord';
import {
  ScenarioUtil,
  getAllSampleIdsFromSample,
} from '../business-logic/evalu8';
import { checkIsSpecificChemicalPlan } from '../business-logic/figure';
import {
  LAYER_TYPES,
  checkIsLayerAlwaysHidden,
  checkIsLayerSearchMatched,
  flattenLayerTree,
} from '../business-logic/layer';
import {
  checkHasResultsFully,
  getScopedSamples,
} from '../business-logic/sample';
import { SampleExceedanceColor } from '../business-logic/styling';
import type { FigureStylingRule } from '../business-logic/styling/figure-styling-rule';
import { findFigureStylingRulesByFigureId } from '../business-logic/styling/figure-styling-rule';
import { checkIsFilteredOutBySampleGroupInternal } from '../lib/olbm/layer/sample/utils';
import { checkIsContours } from '../lib/olbm/layer/shape/utils';
import TreeView from '../modules/TreeView';
import * as openlayers from '../modules/openlayers';

export const getCountryName = (state) => (country) => {
  return state.countries[country]?.name ?? '';
};

export const getFeatureTypeNames = (state) => (featureTypeCode) => {
  return (
    state.featureTypes[featureTypeCode]?.names ?? {
      singular: '',
      plural: '',
    }
  );
};

export const getRegions = (state) => (country) => {
  return state.countries[country]?.regions ?? [];
};

export const hasSidebarLegend = (state) => {
  return state.selectedFigure && state.selectedFigure.legend_type == 0;
};

export const getScenario = (state) => (scenario) => {
  return state.allScenarios.find((s) => ScenarioUtil.checkIsEqual(s, scenario));
};

export const allLayers = (state) => {
  return state.allLayers.filter((l) => {
    return (
      l.data.properties.type !== LAYER_TYPES.FOLDER || l.children.length > 0
    );
  });
};

export const checkIsCalloutDelegate = () => (layer) => {
  const { type, isBuiltin } = layer.data.properties;
  return type === LAYER_TYPES.CALL_OUT && isBuiltin;
};

export const visibleLayers = (_, getters) => {
  return getters.allLayers.filter(
    (item) =>
      !getters.checkIsCalloutDelegate(item) &&
      !getters.isLayerHiddenById(item.id)
  );
};

export const getAllOrderedClientLayers = (_, getters) => {
  return getters.allLayers
    .filter((l) => l.visible && !l.hidden_from_story)
    .sort((a, b) => a.order - b.order);
};

// WARNING: This function is only applicable to layers at level 1 or level 2, e.g.
// squares, circles, sample groups etc. It's not applicable to sample layers
// because they could be at level 3 (the sample group is in a folder or the sample
// is in a sub folder) or level 4(the sample is in a sub folder and its sample group
// is in a folder).
export const getParentLayerById = (state) => (id) => {
  return state.allLayers.find(
    (pL) =>
      pL.id == id ||
      (pL.children && pL.children.findIndex((cL) => cL.id == id) != -1)
  );
};

export const getLayerById = (state) => (id) => {
  return TreeView.searchTree(state.allLayers, (node) => {
    return node.id === id;
  });
};

export const getLayerBySampleId = (state) => (sampleId) => {
  return TreeView.searchTree(
    state.allLayers,
    (item) => item.data.polyGatherSample?.id === sampleId
  );
};

export const cleanShapeProperties = (state) => {
  const properties = state.shapeProperties;
  ['dashArray'].forEach((property) => {
    delete properties[property];
  });

  return properties;
};

export const getAllSiteBoundaryLayers = (state) => {
  return TreeView.searchTree(
    state.allLayers,
    (node) => {
      return node.data.properties.type == 'siteboundary';
    },
    true
  );
};

export const getSiteBoundaryLayers = (state, getters) => {
  return getters.getAllSiteBoundaryLayers.filter((l) => l.visible);
};

export const isLayerHiddenById = (state, getters) => (layer_id) => {
  let layer = getters.getLayerById(layer_id);

  if (!layer) {
    return false;
  }

  const { type } = layer.data.properties;
  if (type === 'sub_folder') {
    const { sampleGroupId } = layer.data.properties;
    const sampleGroup = getters.getLayerById(sampleGroupId);
    const hiddenSubFolders = JSON.parse(sampleGroup.hidden_sub_folders) ?? [];
    return !!hiddenSubFolders?.includes(layer.text);
  }

  return !layer.visible;
};

export const getSampleById = (state) => (id) => {
  return state.allSamples.find((item) => item.id == id);
};

export const getSampleByIdEx = (state) => (id) => {
  const { allSamples, polyGatherSamples } = state;
  return [...allSamples, ...Object.values(polyGatherSamples)].find(
    (item) => item.id === id
  );
};

export const sampleGroups = (state) => {
  return TreeView.searchTree(
    state.allLayers,
    (item) => item.data.properties.type === LAYER_TYPES.SAMPLE_GROUP,
    true
  );
};

export const getSampleGroupById = (state) => (id) => {
  return TreeView.getSampleGroupById(state.allLayers, id);
};

export const getSampleGroupByIdentifier = (state) => (identifier) => {
  if (!identifier) {
    return TreeView.searchTree(state.allLayers, (node) => {
      return node.data.properties && node.data.properties.default;
    });
  }

  let sampleGroup = TreeView.searchTree(state.allLayers, (node) => {
    return node.data.marker_identifier == identifier;
  });

  return sampleGroup;
};

// Builtin call-outs need a delegate to do editing. The delegate is a call-out and
// a project can have only one delegate.
export const builtinCalloutDelegate = (state) => {
  return TreeView.searchTree(state.allLayers, (item) => {
    const { type, isBuiltin } = item.data.properties;
    return type === LAYER_TYPES.CALL_OUT && isBuiltin;
  });
};

export const getResultExceedanceBySample = (state) => (sample, type) => {
  const selectedFigure = state.selectedFigure;
  const currentScenarios = selectedFigure.scenarios;
  const isChemicalFigure = !!selectedFigure.chemicals;
  const hiddenFormattedDepths =
    selectedFigure.enviro_callout_filter?.hiddenFormattedDepths ?? [];

  const allSampleIds = getAllSampleIdsFromSample(sample, hiddenFormattedDepths);

  // Filter results by samples that exceed
  let itemIdsExceeding: any[] = [];
  if (isChemicalFigure && type === 'sample') {
    const results = selectedFigure.results || [];
    results.forEach((chemical) => {
      itemIdsExceeding = [
        ...itemIdsExceeding,
        ...chemical.sample_items
          .filter((r) => allSampleIds.includes(r.sample_id))
          .map((r) => r.id),
      ];
    });
  }

  const exceedanceTypes: any[] = [];
  currentScenarios.forEach((scenario) => {
    const scenarioType = state.allExceedances.find(
      (e) => e.criteria_type == scenario.criteria_type
    );

    const exceedanceData =
      scenarioType?.exceedances.filter(
        (e) =>
          ((scenario.criteria_type == 'landuse' &&
            e.scenario_id == scenario.scenario_id) ||
            (scenario.criteria_type == 'criteria' &&
              e.criteria_set_id == scenario.criteria_set_id)) &&
          e.exceedances.findIndex((s) => allSampleIds.includes(s.sample_id)) !=
          -1 &&
          (e.document_id ? scenario.document_id == e.document_id : true)
      ) ?? [];

    if (exceedanceData.length == 0) {
      return false;
    }

    // chemical based figures should only show an exceedance if any of the results in popup exceed
    exceedanceData.forEach((exceedance) => {
      if (type === 'sample') {
        if (
          isChemicalFigure &&
          exceedance.exceedances.filter((r) =>
            itemIdsExceeding.includes(r.item_id)
          ).length > 0
        ) {
          exceedanceTypes.push(scenario.criteria_type);
        }

        if (!isChemicalFigure) {
          exceedanceTypes.push(scenario.criteria_type);
        }
      } else {
        exceedanceTypes.push({
          criteria_type: scenario.criteria_type,
          ...exceedance,
        });
      }
    });
  });

  return exceedanceTypes;
};

export const isLabelHiddenGlobal = (state) => {
  return state.selectedFigure.hide_sample_labels;
};

export const getStyleByScenario = (state, getters) => (scenario) => {
  const styles = state.allScenarioStyles;
  const foundScenario = getters.getScenario(scenario);
  if (!foundScenario) {
    return {};
  }

  return _getStyleByScenario(
    {
      styles,
    },
    foundScenario
  );
};

export const getLayersBySection =
  (state) =>
    (section_id, hide_story_layers = true) => {
      let layers = state.allLayers.filter((l) => l.section_id == section_id);

      return layers
        .filter(
          (l) => l.visible && (hide_story_layers ? !l.hidden_from_story : true)
        )
        .sort((a, b) => a.order - b.order);
    };

export const canSampleBeDeleted = (state, getters) => (sampleId) => {
  const sample = getters.getSampleById(sampleId);
  return (
    sample &&
    checkIsValidLatLng({
      lat: sample.latitude,
      lng: sample.longitude,
    })
  );
};

export const isSampleHidden = (state, getters) => (sampleId) => {
  const sample = getters.getSampleById(sampleId);
  if (!sample) {
    // The sample could represent polyline or polygon layers with data collected from Gather.
    const layer = getters.getLayerBySampleId(sampleId);
    return layer ? getters.isLayerHiddenById(layer.id) : true;
  }
  const { sample_group: sampleGroup, sub_folder: subFolder } = sample;
  // The getParentLayerById is used instead of getLayerById because
  // the sampleGroup's parent_id is obsolete after layers are reordered.
  const sampleGroupParent = getters.getParentLayerById(sampleGroup.id);
  const subFolderLayer = TreeView.findSubFolderLayer(sampleGroup, subFolder);
  return (
    // sampleGroup.id is null when the sample group is a newly created one.
    // See SampleEvents.prepareSampleGroup.
    // In this situation is the sampleGroupParent null.
    getters.isLayerHiddenById(sampleGroupParent?.id) ||
    getters.isLayerHiddenById(sampleGroup.id) ||
    getters.isLayerHiddenById(subFolderLayer?.id)
  );
};

export const getSampleExceedanceColor = (state, getters) => (sample) => {
  const exceedances = getters.getResultExceedanceBySample(sample, 'sample');

  if (exceedances.includes('landuse')) {
    return SampleExceedanceColor.Landuse;
  }

  if (exceedances.includes('criteria')) {
    return SampleExceedanceColor.Criteria;
  }

  return SampleExceedanceColor.NoExceedances;
};

export const checkHasResults = () => (figure, sample) => {
  return !!figure.results?.find(
    (result) =>
      !!result.sample_items.find((si) => {
        return si.sample_id === sample.id && si.prefix === null;
      })
  );
};

export const checkHasExceedances = (state) => (figure, sample) => {
  return (
    figure.results?.some((r /*SampleChemical*/) => {
      return r.sample_items.some((si) => {
        return (
          si.sample_id === sample.id &&
          state.allExceedances.some((e) => {
            return e.exceedances.some((ee) => {
              return ee.exceedances.some((eee) => eee.item_id === si.id);
            });
          })
        );
      });
    }) ?? false
  );
};

export const getFormattedDepths = (state, getters) => (figure, sample) => {
  const result: (string | null)[] = [];

  if (getters.checkHasResults(figure, sample)) {
    result.push(generalMixin.methods.formatSampleDepth(sample));
  }

  if (sample.duplicate && getters.checkHasResults(figure, sample.duplicate)) {
    result.push(generalMixin.methods.formatSampleDepth(sample.duplicate));
  }

  (sample.child_samples ?? []).forEach((sample) => {
    if (getters.checkHasResults(figure, sample)) {
      result.push(generalMixin.methods.formatSampleDepth(sample));
    }

    if (sample.duplicate && getters.checkHasResults(figure, sample.duplicate)) {
      result.push(generalMixin.methods.formatSampleDepth(sample.duplicate));
    }
  });

  return _uniq(result).sort();
};

export const getAllFormattedDepths = (state, getters) => (figure) => {
  const result: any[] = [];
  state.allSamples.forEach((sample) => {
    result.push(...getters.getFormattedDepths(figure, sample));
  });
  return _uniq(result).sort();
};

export const checkIsCcAlwaysHidden = (state, getters) => (sample) => {
  const { latitude: lat, longitude: lng } = sample;
  const { hide_ecs_without_exceedances, enviro_callout_filter } =
    state.selectedFigure;
  const { hiddenFormattedDepths = [] } = enviro_callout_filter ?? {};
  return (
    !checkIsValidLatLng({
      lat,
      lng,
    }) ||
    getters.isSampleHidden(sample.id) ||
    (hide_ecs_without_exceedances &&
      getters.getSampleExceedanceColor(sample) ===
      SampleExceedanceColor.NoExceedances) ||
    !getters
      .getFormattedDepths(state.selectedFigure, sample)
      .some((fd) => !hiddenFormattedDepths.includes(fd))
  );
};

export const checkIsCcHidden = (state, getters) => (sample) => {
  const popups_hidden = state.selectedFigure.popups_hidden ?? [];
  return (
    getters.checkIsCcAlwaysHidden(sample) || popups_hidden.includes(sample.id)
  );
};

export const checkIsGcAlwaysHidden = (state, getters) => (sample) => {
  const { latitude: lat, longitude: lng } = sample;
  return (
    !checkIsValidLatLng({
      lat,
      lng,
    }) || getters.isSampleHidden(sample.id)
  );
};

export const checkIsGcHidden = (state, getters) => (sample) => {
  const popups_hidden = state.selectedFigure.popups_hidden ?? [];
  return (
    getters.checkIsGcAlwaysHidden(sample) || popups_hidden.includes(sample.id)
  );
};

export const checkIsExceedanceStylingUsed = (state) => (sample) => {
  const { selectedFigure } = state;
  if (!selectedFigure) {
    return false;
  }

  const { hide_sample_exceedance_styling: hideSampleExceedanceStyling } =
    selectedFigure;

  return (
    !hideSampleExceedanceStyling &&
    checkIsSpecificChemicalPlan(selectedFigure) &&
    checkHasResultsFully(sample, selectedFigure)
  );
};

export const isServiceLayerFolderLayer = () => (properties) => {
  if (!properties) {
    // When this method is called to check an Image layer, the properties is null.
    return false;
  }

  const {
    type,
    // For ESRI service layers.
    esri_type,
    // For OGC OpenGIS service layers.
    service,
  } = properties;

  return type === 'folder' && (esri_type || service);
};

export const getServiceAttributions = (state) => {
  const { allLayers } = state;
  const serviceLayerFolderLayers = allLayers.filter((item) => {
    const { type, service, esri_type } = item.data.properties;
    return (
      type === 'folder' &&
      (!!service || !!esri_type) &&
      item.visible &&
      item.children.findIndex((item2) => item2.visible) !== -1
    );
  });
  serviceLayerFolderLayers.reverse();

  return serviceLayerFolderLayers
    .map((item) => {
      const { service } = item.data.properties;
      return !!service
        ? service.attributions
        : item.data.properties.attributions;
    })
    .filter((item, index, self) => {
      // Remove the duplicates.
      return self.indexOf(item) === index;
    });
};

export const getAllBufferLayers = (state) => {
  return state.allLayers.filter(
    (item) => item.data.properties.type === LAYER_TYPES.BUFFER
  );
};

export const getBufferLayer = (state, getters) => (sourceLayer) => {
  if (!sourceLayer) {
    return null;
  }

  const {
    id: layerId,
    properties: { type: layerType },
  } = sourceLayer.data;

  const allBufferLayers = getters.getAllBufferLayers;
  if (layerType === 'folder') {
    return allBufferLayers.find(
      (item) => item.data.properties.folderId === layerId
    );
  } else if (state.bufferableLayerTypes.includes(layerType)) {
    return allBufferLayers.find((item) => {
      const { folderId, boundLayerIds } = item.data.properties;
      return !folderId && boundLayerIds.includes(layerId);
    });
  }

  return null;
};

export const findBufferLayersByBoundLayerId = (state) => (boundLayerId) => {
  return TreeView.searchTree(
    state.allLayers,
    (layer) => {
      const { type, boundLayerIds = [] } = layer.data.properties;
      return (
        type === LAYER_TYPES.BUFFER && boundLayerIds.includes(boundLayerId)
      );
    },
    true
  );
};

export const getBufferLayerDefaultTitle = (state, getters) => {
  const { shapeProperties } = state;

  if (shapeProperties.type !== LAYER_TYPES.BUFFER) {
    throw `The current layer(${shapeProperties.type}) must be a buffer layer.`;
  }

  let { title } = shapeProperties;
  const { distance, folderId, boundLayerIds } = shapeProperties;
  let sourceLayer;
  if (folderId) {
    sourceLayer = getters.getLayerById(folderId);
  } else {
    sourceLayer = getters.getLayerById(boundLayerIds[0]);
  }
  const { text: sourceTitle } = sourceLayer;

  if (
    !title ||
    !title.trim() ||
    title.match(new RegExp(`^\\d+m buffer around ${sourceTitle}$`))
  ) {
    title = `${distance}m buffer around ${sourceTitle}`;
  }

  return title;
};

export const getAllCallouts = (state) => {
  return TreeView.searchTree(
    state.allLayers,
    (item) => item.data.properties.type === LAYER_TYPES.CALL_OUT,
    true
  );
};

export const checkIsGeoreferencing = (state) => {
  const {
    shapeProperties: { type },
    imageEditMethodCode,
  } = state;
  return (
    type === LAYER_TYPES.IMAGE &&
    imageEditMethodCode ===
    openlayers.interactions.image.constants.EDIT_METHOD_CODES.GEOREFERENCE
  );
};

// A gather sample represents a point, or a polyline, or a ploygon with
// data collected from Gather.
export const allGatherSamples = (state) => {
  const { allSamples } = state;
  const pointGatherSamples = allSamples.filter(
    (item) => !!item.template_tab_id
  );
  const polyGatherSamples = Object.values(state.polyGatherSamples);
  return [...pointGatherSamples, ...polyGatherSamples];
};

export const checkGatherAppExists = (state) => (gatherAppId) => {
  return state.gatherApps.some((ga) => ga.id === gatherAppId);
};

export const getLockableLayerIds = (state) => (figureId) => {
  const otherFigures = state.allFigures.filter((f) => f.id !== figureId);
  const lockedLayerIds = otherFigures.reduce((accu, f) => {
    accu.push(...(f.locked_layer_ids ?? []));
    return accu;
  }, []);
  return state.allLayers
    .filter((l) => !lockedLayerIds.includes(l.id))
    .map((l) => l.id);
};

export const getLockedByFigure = (state, getters) => (layerId) => {
  let layer = getters.getLayerById(layerId);
  while (layer?.parent_id) {
    layer = getters.getLayerById(layer.parent_id);
  }

  if (!layer) {
    return undefined;
  }

  const finalizer = state.allFigures.find((f) =>
    f.locked_layer_ids?.includes(layer.id)
  );
  const basemapFigure = state.allFigures.find((f) => f.is_basemap);
  // Layers locked by the Basemap figure are still editable in the Basemap figure.
  const isLockedByBasemapFigure =
    !state.selectedFigure.is_basemap && layer.data.isVisibleInBasemapFigure;
  return finalizer ?? (isLockedByBasemapFigure ? basemapFigure : undefined);
};

// The built-in filtering functionality of Liquor-Tree should NOT be used because
// changing layerSearchQuery in the state will trigger its options.store.getter
// function to be called.
export const searchMatchedLayers = (state, getters) => {
  const result = getters.allLayers;
  const { layerSearchQuery, allSamples } = state;
  const pattern = layerSearchQuery ? new RegExp(layerSearchQuery, 'i') : null;
  const layers = flattenLayerTree(result).filter(
    (l) => !checkIsLayerAlwaysHidden(l)
  );
  layers.forEach((l) => {
    const { type } = l.data.properties;
    const isSampleScope = [
      LAYER_TYPES.SAMPLE_GROUP,
      LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER,
    ].includes(type);
    const samples = isSampleScope
      ? getScopedSamples(l, allSamples).filter((s) => {
        if (checkIsFilteredOutBySampleGroup(s)) {
          return false;
        }
        return type === LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER || !s.sub_folder;
      })
      : [];
    const { isSearchMatched, isCausedBySamples } = pattern
      ? checkIsLayerSearchMatched(pattern, l, samples)
      : { isSearchMatched: true, isCausedBySamples: false };
    if (
      l.data.isSearchMatched !== isSearchMatched ||
      l.data.isCausedBySamples !== isCausedBySamples
    ) {
      l.data = {
        ...l.data,
        isSearchMatched,
        isCausedBySamples,
      };
    }
    if (l.state?.visible !== isSearchMatched) {
      l.state = {
        ...l.state,
        visible: isSearchMatched,
      };
    }
  });

  if (pattern) {
    // Make parent visible and expanded if one of its children is visible
    const visibleLayers = layers.filter((l) => l.state?.visible ?? true);
    // Stop a parent from being updated repetitively
    const updatedParents: any[] = [];
    visibleLayers.forEach((vl) => {
      let { parent_id: parentId } = vl;
      while (parentId) {
        const parent = getters.getLayerById(parentId);
        if (!updatedParents.includes(parent)) {
          parent.state = {
            ...parent.state,
            visible: true,
            expanded: true,
          };
          updatedParents.push(parent);
        }
        parentId = parent.parent_id;
      }
    });
  }

  return result;
};

export const hasSearchMatchedLayers = (state, getters) => {
  return getters.searchMatchedLayers.some((sl) => sl.state?.visible ?? true);
};

export const selectedFigureStylingRules = (state): FigureStylingRule[] => {
  return state.selectedFigure?.id
    ? findFigureStylingRulesByFigureId(
      state.figureStylingRules,
      state.selectedFigure.id
    )
    : [];
};

export const createTempFigureStylingRules = (state, getters) => () => {
  return _cloneDeep(getters.selectedFigureStylingRules);
};

export const getPolyGatherSample = (state) => (layerId) => {
  return state.polyGatherSamples[layerId] ?? null;
};

export const getFigureStylingRulesOnLayer =
  (state, getters) =>
    (layerId): FigureStylingRule[] => {
      const { selectedFigureStylingRules } = getters;
      if (!selectedFigureStylingRules.length) {
        return [];
      }

      let layer = getters.getLayerById(layerId);
      if (!layer) {
        return [];
      }

      if (layer.data.properties.type === LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER) {
        layer = getters.getLayerById(layer.data.properties.sampleGroupId);
      }

      let result: any[] = [];
      const { type } = layer.data.properties;
      if (type === LAYER_TYPES.SAMPLE_GROUP) {
        const sfsrsOnEnviroSamples = selectedFigureStylingRules.filter(
          (sfsr) =>
            sfsr.target.type === TargetType.EnviroSamples &&
            sfsr.target.id === layer.id
        );
        result.push(...sfsrsOnEnviroSamples);

        const samples = getScopedSamples(layer, state.allSamples);
        const appIds = _uniq(
          samples
            .map((sample) => {
              const { template_tab_id: appId } = sample;
              return appId;
            })
            .filter((appId) => !!appId)
        );
        const sfsrsOnGatherApp = selectedFigureStylingRules.filter(
          (sfsr) =>
            sfsr.target.type === TargetType.GatherApp &&
            sfsr.target.collectionType === DrawingType.Point &&
            appIds.includes(sfsr.target.id)
        );
        result.push(...sfsrsOnGatherApp);
      } else if (
        [
          LAYER_TYPES.POLYGON,
          LAYER_TYPES.POLYLINE,
          LAYER_TYPES.ARROW,
          LAYER_TYPES.RECTANGLE,
          LAYER_TYPES.CIRCLE,
          LAYER_TYPES.HEDGE,
        ].includes(type)
      ) {
        const { polyGatherSample } = layer.data;
        if (polyGatherSample) {
          const sfsrsOnGatherApp = selectedFigureStylingRules.filter(
            (sfsr) =>
              sfsr.target.type === TargetType.GatherApp &&
              [DrawingType.Polygon, DrawingType.Polyline].includes(
                sfsr.target.collectionType
              ) &&
              sfsr.target.id === polyGatherSample.template_tab_id
          );
          result.push(...sfsrsOnGatherApp);
        }
      }
      return result;
    };

export const findGatherAppById = (state) => (id) => {
  return state.gatherApps.find((gatherApp) => gatherApp.id === id);
};

export const findGatherFieldById = (state) => (id) => {
  return findFieldByIdFromApps(state.gatherApps, id);
};

export const basemapApis = (state, getters, rootState) => {
  const { project } = rootState;
  const country = project.location?.country ?? 'NZ';
  const address = project.address_state;
  return getBasemapApis(country, address);
};

function checkIsFilteredOutBySampleGroup(sample): boolean {
  return checkIsFilteredOutBySampleGroupInternal(
    sample,
    sample.sample_group.data.properties
  );
}

export const layerCount = (state) => {
  return flattenLayerTree(state.allLayers).length;
};

export const sampleCount = (state) => {
  return state.allSamples.length;
};

export const isContours = (state) => {
  const { type, usage } = state.shapeProperties;
  return checkIsContours(type, usage);
};

export const selectedContourId = (state, getters) => {
  const { ID } = state.shapeProperties;
  return getters.isContours ? ID : undefined;
};
