import { SampleKit } from '@component-library/enviro';
import globals from '../globals';

export const enum MatrixType {
  SOIL = 0,
  WATER = 1,
  LEACHATE = 2,
  SOIL_GAS = 3,
  SEDIMENT = 4,
  AIR = 5,
}

export const convertMatrixStringToType = (type: string): MatrixType => {
  const formattedType = type.toLowerCase();

  switch (formattedType) {
    case 'soil':
      return MatrixType.SOIL;
    case 'water':
      return MatrixType.WATER;
    case 'leachate':
      return MatrixType.LEACHATE;
    case 'soilgas':
    case 'gas':
      return MatrixType.SOIL_GAS;
    case 'sediment':
      return MatrixType.SEDIMENT;
  }

  return MatrixType.SOIL;
};

export const convertMatrixTypeToString = (type: MatrixType): string => {
  switch (type) {
    case MatrixType.WATER:
      return 'water';
    case MatrixType.LEACHATE:
      return 'leachate';
    case MatrixType.SOIL_GAS:
      return 'soilgas';
    case MatrixType.SEDIMENT:
      return 'sediment';
  }

  return 'soil';
};

export function getMatrixStylingByKey(key: string) {
  return (
    globals.matrixTypes.find((m) => m['key'] == key) || globals.matrixTypes[0]
  );
}

export function getMatrixTypesByCountry(country: string) {
  return globals.matrixTypes.filter((m) => m.countries.includes(country));
}

export const getMatrixIcon = (matrix: number) => {
  return globals.matrixTypes.find((m) => m.index == matrix)!.icon;
};

export const uploadHasMatrix = (upload: SampleKit) => {
  let hasUploadedMatrix = false;

  for (const matrix of [
    MatrixType.SOIL,
    MatrixType.WATER,
    MatrixType.LEACHATE,
    MatrixType.SOIL_GAS,
    MatrixType.SEDIMENT,
  ]) {
    if (upload['has_' + convertMatrixTypeToString(matrix) + '_upload']) {
      hasUploadedMatrix = true;
      break;
    }
  }

  return hasUploadedMatrix;
};
