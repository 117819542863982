import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import useApi from '../api';
import { LangfuseWeb } from 'langfuse';
import { Project, ProjectType as GlobalProjectType } from '../project';
import { App } from '../gather';
import { useToastStore } from './toasts';
import { postAnalyticsEvent, EVENTS } from '../analytics/index';
import { ChartTypeRegistry } from 'chart.js';
import auth from '../auth';

const queryTypes = [
  {
    id: 'gather',
    name: 'Gather',
    icon: 'fa-map-marker-plus',
  },
  {
    id: 'evalu8',
    name: 'Evalu8',
    icon: 'fa-atom',
  },
];

export const MODES = {
  regular: 'regular',
  assistant: 'assistant',
  scenario: 'scenario',
};

export const getQueryType = (queryTypeID): QueryType | undefined => {
  return queryTypes.find((queryType) => queryType.id === queryTypeID);
};

export interface AIInsight {
  has_insight?: boolean;
  group_id?: number;
}

export interface Feedback {
  trace_id: string;
  user_feedback: number;
  comment: string;
}
export interface QueryType {
  id: string;
  name: string;
  icon: string;
}

export interface Result {
  action: string;
  option: keyof ChartTypeRegistry;
  result: any;
  columns: string[];
  refreshable: boolean;
  valid: boolean;
  sql: string;
}

export interface Polygon {
  area: string;
  buffer: number;
  layer_id: number;
  layer_name: string;
}

export interface QueryMeta {
  keywords: KeywordInQuery[];
  output_formats: string[];
  polygon?: Polygon;
}

export interface Query {
  user_name: string;
  nlq: string;
  created_at: string;
  thread_id?: number;
  query_meta?: QueryMeta;
}

export const FEEDBACK_NONE = 0;
export const FEEDBACK_NEGATIVE = 1;
export const FEEDBACK_POSITIVE = 2;

export type feedback =
  | typeof FEEDBACK_NONE
  | typeof FEEDBACK_POSITIVE
  | typeof FEEDBACK_NEGATIVE;

export interface Response {
  trace_id: string;
  results: Result[];
}

export interface QueryResponseCombo {
  query_id?: number;
  response_id?: number;
  user_name?: string;
  query: Query;
  response: Response;
  thread_id?: number;
  created_at?: string;
  status: string;
  user_feedback?: feedback;
  avatar?: string;
  sub_queries?: QueryResponseCombo[];
  allow_sub_querying?: boolean;
}

export interface ThreadResponse {
  thread_id?: number;
  user_id?: number;
  query_response_pairs: QueryResponseCombo[];
  app?: App;
  project?: Project;
  query_type?: string;
  company_id?: number;
  matrix?: string;
  group_id?: number;
}

export interface ScenarioGroup {
  id: number;
  title: string;
}

export interface ThreadContext {
  thread_id?: number;
  user_id?: number;
  company_id?: number;
  project?: Project;
  query_type?: string;
  app?: App;
  section_id?: number;
  section_name?: string;
  matrix?: string;
  group?: ScenarioGroup;
}

export interface Keywords {
  [key: string]: KeywordInQuery[];
}

export interface KeywordInQuery {
  keyword?: string;
  type: string;
  is_repeatable?: boolean;
}

export type QueryItemType = 'text' | 'keyword' | 'break';
export type QueryItem = {
  type: QueryItemType;
  value?: string;
  keyType?: string;
};

export type Avatar = {
  id: string;
  path: string;
  mini_path: string;
  info: string;
  name: string;
  oneLiners?: string[];
};

export type AvatarType = 'maverick' | 'earhart';

export const SUPPORTED_AVATARS: Avatar[] = [
  {
    id: 'earhart',
    name: 'Amelia Earhart',
    path: '/images/ai-explorer/earhart.svg',
    mini_path: '/images/ai-explorer/earhart_head.svg',
    info: 'Amelia Earhart is a fearless AI pilot who is an explorer at heart.\
     She will dig deep to find the answers you need.',
    oneLiners: [
      'Just like navigating through the clouds, fetching data takes a bit of time. Thanks for your patience!',
      "Amelia here, soaring through the digital skies to bring you the data you seek. Buckle up, we're almost there!",
      "In the world of data, even I can't break the speed of light. Almost there, though!",
      "While I can't fly at the speed of a Lockheed Electra, I'm doing my best to retrieve your data swiftly!",
      "The database is vast, but I'm on a mission to find your data. Hang tight; we're cruising at a good pace!",
      "Just like crossing the Atlantic, fetching data requires a steady course. We'll land at your results soon!",
      'Flying through the SQL clouds, your data is just a few air miles away. Fasten your seatbelt!',
      "Amelia Earhart once said, 'Adventure is worthwhile in itself.' Waiting for data is an adventure, right?",
      "While I don't have a plane, I've got some speedy algorithms. We'll touch down with your data shortly!",
    ],
  },
  {
    id: 'maverick',
    name: 'Top-Gun Maverick',
    path: '/images/ai-explorer/maverick.svg',
    mini_path: '/images/ai-explorer/maverick_head.svg',
    info: 'Top-Gun Maverick is a sharp and confident AI pilot who is always ready to take on any challenging query\
     you throw at him.',
    oneLiners: [
      "I feel the need... the need for speed in fetching your data! Hang tight, we're on the highway to the data zone!",
      'Maverick here, soaring through the information highway. Your data will be in the end zone soon!',
      "Just like a Tomcat on the deck, I'm preparing for takeoff to retrieve your data. It's going to be a wild ride!",
      "I've got the need for data speed! We'll be breaking the sound barrier to bring you the results shortly!",
      "In the world of databases, I'm the Top Gun. Your data is my mission, and I won't leave anyone behind!",
      "I'm not just a chatbot; I'm a digital maverick. Your data will be flying into the end zone in no time!",
      "I don't just fly planes; I fly through databases. Your data is my target, and I'm locked in for retrieval!",
      "Buckle up, because we're on a data sortie! Your information will be landing on the deck shortly!",
      "I've got the Maverick touch when it comes to data. Your results will be a touchdown, no ejections required!",
    ],
  },
];

export type OutputFormat = {
  format: string;
  icon?: string;
  display_name?: string;
};

export const SUPPORTED_OUTPUTS: OutputFormat[] = [
  {
    format: 'sentence',
    icon: 'fa-comment-alt-lines',
    display_name: 'Sentence',
  },
  {
    format: 'table',
    icon: 'fa-table',
    display_name: 'Table',
  },
  {
    format: 'bar',
    icon: 'fa-chart-bar',
    display_name: 'Bar chart',
  },
  {
    format: 'pie',
    icon: 'fa-chart-pie',
    display_name: 'Pie chart',
  },
  {
    format: 'line',
    icon: 'fa-chart-line',
    display_name: 'Line chart',
  },
  {
    format: 'scatter',
    icon: 'fa-chart-scatter',
    display_name: 'Scatter plot',
  },
  // {
  //   format: 'histogram',
  //   icon: 'fa-waveform-path',
  //   display_name: 'Histogram',
  // },
];

export type ExampleQuery = {
  query_string: QueryItem[];
  keywords?: KeywordInQuery[];
  output_formats: Set<string>;
  cross_project?: boolean;
  hierarchy?: string;
};
const EXAMPLE_QUERIES: Record<string, ExampleQuery[]> = {
  evalu8: [
    {
      query_string: [
        {
          type: 'text',
          value:
            'Summarize the `chemical` concentration stats in my samples which exceed `guideline title` guideline.',
        },
      ],
      keywords: [
        {
          type: 'chemical',
        },
        {
          type: 'guideline title',
        },
      ],
      output_formats: new Set(['sentence']),
      cross_project: false,
      hierarchy: 'document',
    },
    {
      query_string: [
        {
          type: 'text',
          value:
            'Summarize the `chemical` concentrations in my samples for the `scenario text` scenario.',
        },
      ],
      keywords: [
        {
          type: 'chemical',
        },
        {
          type: 'scenario text',
        },
      ],
      output_formats: new Set(['sentence']),
      cross_project: false,
      hierarchy: 'scenario',
    },
    {
      query_string: [
        {
          type: 'text',
          value:
            'What is the average concentration of `chemical` in my samples?',
        },
      ],
      keywords: [
        {
          type: 'chemical',
        },
      ],
      output_formats: new Set(['sentence']),
      cross_project: false,
    },
    {
      query_string: [
        {
          type: 'text',
          value:
            'What is the average concentration of `chemical` across each of my projects?',
        },
      ],
      keywords: [
        {
          type: 'chemical',
        },
      ],
      output_formats: new Set(['table']),
      cross_project: true,
    },
    {
      query_string: [
        {
          type: 'text',
          value:
            'Which of my projects have `chemical` exceedences for `guideline title` criteria?',
        },
      ],
      keywords: [
        {
          type: 'chemical',
        },
        {
          type: 'guideline title',
        },
      ],
      output_formats: new Set(['table']),
      cross_project: true,
    },
  ],
  gather: [
    {
      query_string: [
        {
          type: 'text',
          value: 'Show me my samples with their latitudes and longitudes.',
        },
      ],
      output_formats: new Set(['table']),
      cross_project: false,
    },
  ],
};

export const useAIExplorerStore = defineStore('ai-explorer', () => {
  const legacyStore = ref<any | undefined>();
  const toast = useToastStore();
  const threadBeingCreated = ref<boolean>(false);
  const showAIExplorer = ref(false);
  const activeSubQueryId = ref<number>();
  const currentContext = ref<ThreadContext>();
  const currentUsername = ref<string>('');
  const apps = ref<App[]>([]);
  const scenarioGroups = ref<any[]>([]);
  const api = useApi();
  const data = ref<ThreadResponse[]>([]);
  const keywords = ref<Keywords>({});
  const currentQuery = ref<QueryItem[]>([]);
  const currentKeywords = ref<KeywordInQuery[]>([]);
  const keywordMatches = ref<Record<string, KeywordInQuery>>({});
  const currentAvatar = ref<Avatar>(SUPPORTED_AVATARS[0]);
  const loading = ref<boolean>(false);
  const preventScroll = ref<boolean>(false);
  const expectedOutputSet = ref<Set<string>>(new Set(['sentence']));
  const isSetCursorToEnd = ref(false);
  const showMapQueryWindow = ref(false);
  const mode = ref<string>(MODES.regular);
  const mapsLayerNode = ref<any | null>(null);
  const mapsBuffer = ref(0);
  const mapsQueryArea = ref('inside');
  const matrix = ref<string>('soil');
  const aiInsight = ref<AIInsight>({});
  const userSetAssistantVisibility = ref<boolean>(true);
  const isLoadingKeywords = ref<boolean>(false);
  const langfuse =
    import.meta.env.VITE_LANGFUSE_SECRET_KEY &&
    import.meta.env.VITE_LANGFUSE_PUBLIC_KEY &&
    import.meta.env.VITE_LANGFUSE_URL &&
    new LangfuseWeb({
      // @ts-expect-error @Brij is this needed, TS has no definition for this?
      secretKey: import.meta.env.VITE_LANGFUSE_SECRET_KEY,
      publicKey: import.meta.env.VITE_LANGFUSE_PUBLIC_KEY,
      baseUrl: import.meta.env.VITE_LANGFUSE_URL,
    });
  const launchedFrom = ref<string>('direct');

  const setLegacyStore = (store: any) => {
    legacyStore.value = store;
  };

  watch(
    () => [
      legacyStore.value?.state?.project,
      legacyStore.value?.state?.dashboard?.processed_settings,
    ],
    ([project, processedSettings]) => {
      const company = auth.getUser()?.company;
      if (!company) return;
      let currentGroup = processedSettings?.scenario_groups?.find(
        (group) => group.id === processedSettings.group_id
      );
      if (!project) {
        currentGroup = null;
      }
      setCurrentProjectAsContext(project, company.id, currentGroup);
    },
    {
      immediate: true,
      deep: true,
    }
  );

  watch(
    () => currentContext.value,
    () => {
      if (
        currentContext.value &&
        currentContext.value.group === undefined &&
        legacyStore.value?.state?.dashboard?.processed_settings?.group_id &&
        legacyStore.value?.state.project
      ) {
        const currentGroup =
          legacyStore.value?.state?.dashboard?.processed_settings?.scenario_groups?.find(
            (group) =>
              group.id ===
              legacyStore.value?.state?.dashboard?.processed_settings?.group_id
          );
        currentContext.value.group = currentGroup;
      }
    }
  );

  const setLaunchedFrom = (from: string) => {
    launchedFrom.value = from;
  };
  const setAssistantVisibility = (visible: boolean) => {
    userSetAssistantVisibility.value = visible;
  };

  const showAssistant = computed(() => {
    if (!userSetAssistantVisibility.value) return false;
    const insight = aiInsight.value?.has_insight;
    if (!insight) return false;
    const lastClicked = JSON.parse(
      localStorage.getItem('ai-assistant-clicks') ?? '{}'
    );
    const groupId = aiInsight.value?.group_id;
    if (!groupId) return false;

    const lastClickedTime = new Date(lastClicked[groupId]).getTime();
    const timeSinceLastClick = Date.now() - lastClickedTime;

    if (timeSinceLastClick < 1000 * 60 * 60 * 24) return false; // Hide if clicked in last 24 hours.

    return true;
  });

  const showAIExplorerInAssistantMode = computed(() => {
    return showAIExplorer.value && mode.value === MODES.assistant;
  });

  const showAIExplorerRegularMode = computed(() => {
    return showAIExplorer.value && mode.value === MODES.regular;
  });

  const showAIExplorerScenarioMode = computed(() => {
    return showAIExplorer.value && mode.value === MODES.scenario;
  });

  function setMatrix(matrixType: string) {
    matrix.value = matrixType;
  }

  function clearCache() {
    currentQuery.value = [];
    currentKeywords.value = [];
    keywordMatches.value = {};
    currentAvatar.value = SUPPORTED_AVATARS[0];
    expectedOutputSet.value = new Set(['sentence']);
    data.value = [];
    mapsBuffer.value = 0;
    mapsQueryArea.value = 'inside';
    showMapQueryWindow.value = false;
    mapsLayerNode.value = null;
    matrix.value = 'soil';
    mode.value = MODES.regular;
  }

  function clearMapsContext() {
    mapsQueryArea.value = 'inside';
    showMapQueryWindow.value = false;
    mapsLayerNode.value = null;
    mapsBuffer.value = 0;
    mode.value = MODES.regular;
  }

  function setActiveSubQueryId(id: number) {
    activeSubQueryId.value = id;
  }

  function clearActiveSubQueryId() {
    activeSubQueryId.value = undefined;
  }

  function setCurrentAIAvatarInThread(avatar: Avatar) {
    currentAvatar.value = avatar;
  }

  function toggleThreadBeingCreated() {
    threadBeingCreated.value = !threadBeingCreated.value;
  }

  function setCurrentProjectAsContext(
    project: Project,
    companyId: number | undefined,
    group: ScenarioGroup | null = null
  ) {
    const isEnviro =
      project === undefined ||
      project?.project_type === GlobalProjectType.ENVIRO;
    if (!isEnviro) {
      expectedOutputSet.value = new Set(['table']);
    }
    currentContext.value = {
      query_type: isEnviro ? 'evalu8' : 'gather',
      project: project,
      company_id: companyId,
      thread_id: undefined,
    };
    if (group) {
      currentContext.value.group = group;
    }
  }

  watch(
    () => showAIExplorer.value,
    (value) => {
      if (value) {
        getKeywords();
      }
    }
  );

  function getRandomKeyword(type) {
    const randomKeyword =
      keywords.value[type]?.[
        Math.floor(Math.random() * keywords.value[type]?.length)
      ]?.keyword;
    return randomKeyword;
  }

  const preloadedQueries = computed(() => {
    if (!currentContext.value || !keywords.value) {
      return [];
    }
    const crossProjectQuery = currentContext.value.project === undefined;
    const queryType = currentContext.value.query_type ?? 'evalu8';
    const hierarchy = legacyStore?.value?.state?.dashboard?.processed_settings
      ?.group_id
      ? legacyStore?.value?.state?.dashboard?.processed_settings
        ?.by_documents === 1
        ? 'document'
        : 'scenario'
      : undefined;
    return EXAMPLE_QUERIES[queryType]
      .filter((query) => {
        if (query.cross_project || crossProjectQuery) {
          return crossProjectQuery === query.cross_project;
        }

        if (query.hierarchy) {
          return query.hierarchy === hierarchy;
        }

        return true;
      })
      .map((query) => {
        let newQueryString = [
          {
            type: 'text',
            value: query.query_string[0].value?.repeat(1) ?? '',
          },
        ];
        const queryKeywords = query.keywords?.map((keyword) => {
          const randomWord = getRandomKeyword(keyword.type);
          if (!randomWord) return keyword;
          const regex = new RegExp(`\`${keyword.type}\``, 'g');
          newQueryString[0].value =
            newQueryString[0].value?.replace(regex, `\`${randomWord}\``) ?? '';
          return {
            keyword: randomWord,
            type: keyword.type,
          };
        });
        return {
          query_string: newQueryString,
          keywords: queryKeywords,
          output_formats: query.output_formats,
        } as ExampleQuery;
      });
  });

  function shouldSuggestToUseKeywords(queryItems: QueryItem[]) {
    const hasKeywords = queryItems.some(
      (queryItem) => queryItem.type === 'keyword'
    );
    if (hasKeywords) {
      return false;
    }
    const wordsInQuery = queryItems
      .map((queryItem) => queryItem.value?.trim())
      .join(' ')
      .split(' ');
    let foundMatch = false;
    wordsInQuery.forEach((word) => {
      const keys = Object.keys(keywords.value);
      for (let i = 0; i < keys.length; i++) {
        if (foundMatch) {
          break;
        }
        const key = keys[i];
        for (let j = 0; j < keywords.value[key].length; j++) {
          const keyword = keywords.value[key][j].keyword;
          if (word.toLowerCase() === keyword?.toLowerCase()) {
            foundMatch = true;
            break;
          }
        }
      }
    });
    return foundMatch;
  }

  function setCurrentThreadContext(threadContext: ThreadContext) {
    currentContext.value = threadContext;
    expectedOutputSet.value = new Set(['table']);
    if (showAIExplorer.value) {
      getKeywords();
    }
  }

  function getKeyWordFromIndex(index: number) {
    const element = Object.entries(keywordMatches.value).find(
      (_, i) => index - 1 === i
    );
    return element ? element[1] : null;
  }

  function addToExpectedOutput(expectedFormat: OutputFormat) {
    expectedOutputSet.value = new Set(
      expectedOutputSet.value.add(expectedFormat.format)
    );
  }

  function removeFromExpectedOutput(expectedFormat: OutputFormat) {
    expectedOutputSet.value = new Set(
      [...expectedOutputSet.value].filter(
        (format) => format !== expectedFormat.format
      )
    );
  }

  function clearExpectedOutput() {
    expectedOutputSet.value = new Set();
  }

  function addToQuery(type: QueryItemType, value: string) {
    currentQuery.value.push({
      type: type,
      value: value,
    });
    isSetCursorToEnd.value = true;
  }

  function fetchAIAssistantShowStatus() {
    if (!currentContext.value) {
      aiInsight.value = {
        has_insight: false,
      };
    }
    api
      .get('llm-explorer/assistant/ai_insight', {
        params: {
          company_id: currentContext.value?.company_id,
          project_id: currentContext.value?.project?.project_id,
          group_id: currentContext.value?.group?.id,
        },
      })
      .then((response) => {
        aiInsight.value = response.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  function setFullQuery(query: QueryItem[]) {
    currentQuery.value = query;
  }

  function clearKeywordMatches() {
    keywordMatches.value = {};
  }

  function clearCurrentKeywords() {
    currentKeywords.value = [];
  }

  function clearCurrentQuery() {
    currentQuery.value = [];
  }

  function addKeywordDirectly(keywordInQuery: KeywordInQuery) {
    currentKeywords.value.push(keywordInQuery);
  }

  function addKeyword(keywordInQuery: KeywordInQuery) {
    const lastWords =
      currentQuery.value?.[currentQuery.value.length - 1].value?.split(' ');

    currentQuery.value = [
      ...currentQuery.value.slice(0, currentQuery.value.length - 1),
      {
        type: 'text',
        value: lastWords?.slice(0, lastWords.length - 1)?.join(' ') + ' ',
      },
      {
        keyType: keywordInQuery.type,
        type: 'keyword',
        value: keywordInQuery.keyword,
      },
    ];
    currentKeywords.value = [...currentKeywords.value, keywordInQuery];
  }

  function submitFeedback(
    feedback: number,
    comment: string,
    queryId: number,
    threadId: number,
    traceId: string
  ) {
    preventScroll.value = true;
    api
      .post('/llm-explorer/feedback/' + queryId, {
        user_feedback: feedback,
      })
      .then(() => {
        updateQueryStatus('sql', threadId);
        if (feedback !== FEEDBACK_NONE && traceId) {
          submitLangfuseFeedback({
            trace_id: traceId,
            user_feedback: feedback,
            comment: comment,
          });
          postAnalyticsEvent({
            event: EVENTS.aiExplorer,
            properties: {
              action: 'feedback',
              value: feedback,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function submitLangfuseFeedback(feedback: Feedback) {
    if (!langfuse) {
      throw new Error('Langfuse not initialized');
    }
    await langfuse.score({
      traceId: feedback.trace_id,
      name: 'user-feedback',
      value: feedback.user_feedback - 1,
      comment: feedback.comment,
    });
  }

  function updateQueryStatus(toQueryType, threadId) {
    api
      .get('llm-explorer/' + threadId, {
        params: {
          to_query_type: toQueryType,
        },
      })
      .then((response) => {
        const threadToReplace: ThreadResponse = response.data[0];
        let matchFound = false;
        data.value = data.value.map((thread) => {
          if (thread.thread_id === threadToReplace.thread_id) {
            matchFound = true;
            return threadToReplace;
          }
          return thread;
        });
        if (!matchFound) {
          removeTemporaryThread();
          data.value.push(threadToReplace);
          setCurrentThreadContext({
            query_type: threadToReplace.query_type,
            project: threadToReplace.project,
            app: threadToReplace.app,
            company_id: threadToReplace.company_id,
            thread_id: threadToReplace.thread_id,
          });
          const queryResponsePairs = threadToReplace.query_response_pairs;
          setCurrentAIAvatarInThread(
            SUPPORTED_AVATARS.find(
              (avatar) =>
                queryResponsePairs[queryResponsePairs.length - 1].avatar ===
                avatar.id
            ) ?? SUPPORTED_AVATARS[0]
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeTemporaryThread() {
    data.value = data.value.filter((thread) => {
      return thread.thread_id !== undefined && thread.thread_id !== -1;
    });
  }

  function getKeywords() {
    const params = {};
    if (currentContext.value?.query_type) {
      params['query_type'] = currentContext.value.query_type;
    }
    if (currentContext.value?.project) {
      params['project_id'] = currentContext.value.project.project_id;
    }
    if (currentContext.value?.app) {
      params['app_id'] = currentContext.value.app.id;
    }
    if (currentContext.value?.group) {
      params['group_id'] = currentContext.value.group.id;
    }
    if (matrix.value) {
      params['matrix'] = matrix.value;
    }
    isLoadingKeywords.value = true;
    api
      .get('/llm-explorer/keywords', {
        params: params,
      })
      .then((res) => {
        keywords.value = res.data.keywords;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        isLoadingKeywords.value = false;
      });
  }

  function addQueryToEnd() {
    if (activeSubQueryId.value) {
      let subQuery: QueryResponseCombo = {
        query: {
          user_name: '',
          nlq: getCurrentQueryString(),
          created_at: '',
        },
        response: {
          trace_id: '',
          results: [],
        },
        status: 'queued',
      };
      let queryIndex;
      const threadIndex = data.value.findIndex((thread) => {
        let threadFound = false;
        thread.query_response_pairs.forEach((queryResponsePair, index) => {
          if (queryResponsePair.query_id === activeSubQueryId.value) {
            threadFound = true;
            queryIndex = index;
          }
        });
        return threadFound;
      });
      data.value = [
        ...data.value.slice(0, threadIndex),
        {
          ...data.value[threadIndex],
          query_response_pairs: [
            ...data.value[threadIndex].query_response_pairs.slice(
              0,
              queryIndex
            ),
            {
              ...data.value[threadIndex].query_response_pairs[queryIndex],
              sub_queries: [
                ...(data.value[threadIndex].query_response_pairs[queryIndex]
                  .sub_queries ?? []),
                subQuery,
              ],
            },
            ...data.value[threadIndex].query_response_pairs.slice(
              queryIndex + 1
            ),
          ],
        },
        ...data.value.slice(threadIndex + 1),
      ];
    } else {
      let latestQuery: QueryResponseCombo = {
        user_name: currentUsername.value,
        query: {
          user_name: '',
          nlq: getCurrentQueryString(),
          created_at: '',
        },
        response: {
          trace_id: '',
          results: [],
        },
        created_at: '',
        status: 'queued',
        avatar: currentAvatar.value?.id ?? SUPPORTED_AVATARS[0].id,
        user_feedback: FEEDBACK_NONE,
      };
      const currentThreadID = currentContext.value?.thread_id;
      const lastThread = data.value[data.value.length - 1]?.thread_id;
      if (currentThreadID !== lastThread || data.value.length === 0) {
        data.value = [
          ...data.value,
          {
            thread_id: currentThreadID ?? -1,
            query_response_pairs: [latestQuery],
            app: currentContext.value?.app,
            project: currentContext.value?.project,
            query_type: currentContext.value?.query_type,
            company_id: currentContext.value?.company_id,
          },
        ];
      } else {
        if (data.value.length > 0) {
          data.value = [
            ...data.value.slice(0, data.value.length - 1),
            {
              ...data.value[data.value.length - 1],
              query_response_pairs: [
                ...data.value[data.value.length - 1].query_response_pairs,
                latestQuery,
              ],
            },
          ];
        }
      }
    }
  }

  function getQueryString(queryString: QueryItem[]) {
    return queryString
      .map((queryItem) => {
        if (queryItem.type === 'keyword') {
          return '`' + queryItem.value + '`';
        } else {
          return queryItem.value?.replaceAll('&nbsp;', ' ');
        }
      })
      .join('');
  }

  function getCurrentQueryString() {
    return getQueryString(currentQuery.value);
  }

  function retryQuery(queryId: number, failureCallback: () => void) {
    api
      .post('/llm-explorer/query/' + queryId, {
        project_id: currentContext.value?.project?.project_id,
        group_id: currentContext.value?.group?.id,
        matrix: matrix.value,
        type: currentContext.value?.query_type,
        output_formats: Array.from(expectedOutputSet.value),
      })
      .catch((err) => {
        loading.value = false;
        handleErrorMessage(err, 'Error retrying query');
        failureCallback();
      });
  }

  function refreshData(
    responseId: number,
    threadId: number,
    failureCallback: () => void
  ) {
    const errorMessage =
      'Error refreshing data, please try again or retry the whole query again.';
    api
      .get('/llm-explorer/response/' + responseId, {})
      .then((res) => {
        const responseData = res.data;
        const threadToUpdate = data.value.find(
          (thread) => thread.thread_id === threadId
        );
        if (!threadToUpdate) {
          toast.error(errorMessage);
          failureCallback();
          return;
        }
        const queryResponsePairToUpdate: QueryResponseCombo | undefined =
          threadToUpdate.query_response_pairs.find(
            (queryResponsePair) => queryResponsePair.response_id === responseId
          );
        const indexToUpdate = threadToUpdate.query_response_pairs.findIndex(
          (queryResponsePair) => queryResponsePair.response_id === responseId
        );
        if (
          !queryResponsePairToUpdate ||
          indexToUpdate === -1 ||
          responseData.length == 0
        ) {
          toast.error(errorMessage);
          failureCallback();
          return;
        }
        const queryResponse = queryResponsePairToUpdate.response;
        const results = queryResponse.results;
        responseData.forEach((data, index) => {
          results[index].result = data.data;
          results[index].valid = data.valid;
        });
        queryResponse.results = results;
        const newQueryResponsePairToUpdate = {
          ...queryResponsePairToUpdate,
          response: {
            ...queryResponse,
          },
        };
        threadToUpdate.query_response_pairs[indexToUpdate] =
          newQueryResponsePairToUpdate;
        data.value = data.value.map((thread) => {
          if (thread.thread_id === threadId) {
            return threadToUpdate;
          }
          return thread;
        });
        toast.success('Data refreshed successfully');
      })
      .catch((err) => {
        handleErrorMessage(err, errorMessage);
        failureCallback();
      });
  }

  function submitQuery() {
    makeRequest();
    currentQuery.value = [];
    currentKeywords.value = [];
    keywordMatches.value = {};
    if (activeSubQueryId) {
      clearActiveSubQueryId();
      preventScroll.value = true;
    } else {
      preventScroll.value = false;
    }
  }

  function deleteThread(threadId: number) {
    if (!threadId) {
      console.error('Thread ID not provided');
      return;
    }
    api
      .delete('/llm-explorer/thread/' + threadId)
      .then(() => {
        if (threadId === currentContext.value?.thread_id) {
          currentContext.value = {
            ...currentContext.value,
            thread_id: undefined,
          };
        }
        data.value = data.value.filter(
          (thread) => thread.thread_id !== threadId
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function makeRequest() {
    if (!currentContext.value) {
      toast.error(
        'Current Context for query not set, please set current context.'
      );
      throw new Error('Current Context not set');
    }
    if (!currentAvatar.value) {
      toast.error(
        'Current Avatar for query not set, please set current avatar.'
      );
      throw new Error('Current Avatar not set');
    }
    addQueryToEnd();
    const requestParams = {
      query_string: getCurrentQueryString(),
      query_meta: {
        output_formats: Array.from(expectedOutputSet.value),
        keywords: currentKeywords.value,
      },
      project_id: currentContext.value.project?.project_id,
      app_id: currentContext.value.app?.id,
      type: currentContext.value.query_type,
      to: 'sql',
      company_id: currentContext.value.company_id,
      from: 'nlq',
      thread_id: currentContext.value.thread_id,
      avatar: currentAvatar.value.id,
      matrix: matrix.value,
      query_id: activeSubQueryId.value,
      group_id: currentContext.value.group?.id,
      output_formats: Array.from(expectedOutputSet.value),
    };
    if (showMapQueryWindow.value && mapsLayerNode.value) {
      requestParams['query_meta']['polygon'] = {
        layer_id: mapsLayerNode.value.id,
        buffer: mapsBuffer.value,
        area: mapsQueryArea.value,
        layer_name: mapsLayerNode.value.text,
      };
      closeMapQueryWindow();
    }

    api
      .post('/llm-explorer/query', requestParams)
      .catch((err) => {
        console.error(err);
        throw err;
      })
      .finally(() => {
        postAnalyticsEvent({
          event: EVENTS.aiExplorer,
          properties: {
            action: 'query',
            type: currentContext.value?.query_type,
            launched_from: launchedFrom.value,
          },
        });
      });
  }

  function getApps(projectID) {
    api
      .get('/llm-explorer/apps', {
        params: {
          project_id: projectID,
        },
      })
      .then((res) => {
        apps.value = res.data.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  function getScenarioGroups(projectID) {
    api
      .get('/llm-explorer/scenario-groups', {
        params: {
          project_id: projectID,
        },
      })
      .then((res) => {
        scenarioGroups.value = res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  function loadAIExplorerData(
    companyId,
    currentProject: Project | null = null,
    group: ScenarioGroup | undefined = undefined
  ) {
    loading.value = true;
    const params = {
      project_id: currentProject?.project_id,
      mode: mode.value,
    };
    if (group && showAIExplorerScenarioMode) {
      params['group_id'] = group.id;
    }
    api
      .get('/llm-explorer', {
        params: params,
      })
      .then((response) => {
        loading.value = false;
        data.value = response.data;
        if (data.value.length === 0) {
          if (!currentProject) {
            setCurrentThreadContext({
              query_type: 'evalu8',
              company_id: companyId,
              group: group,
            });
          } else {
            getApps(currentProject.project_id);
          }
          return;
        }
        const lastContext: ThreadResponse = data.value[data.value.length - 1];
        if (
          currentProject &&
          (!lastContext?.project ||
            lastContext?.project.project_id !== currentProject.project_id)
        ) {
          getApps(currentProject.project_id);
        } else {
          setCurrentThreadContext({
            query_type: lastContext.query_type,
            project: lastContext.project,
            app: lastContext.app,
            company_id: companyId,
            thread_id: lastContext.thread_id,
            group: group,
          });
          if (lastContext.project?.project_id) {
            getApps(lastContext.project?.project_id);
          }
        }
        setCurrentAIAvatarInThread(
          SUPPORTED_AVATARS.find(
            (avatar) =>
              lastContext.query_response_pairs[
                lastContext.query_response_pairs.length - 1
              ]?.avatar === avatar.id
          ) ?? SUPPORTED_AVATARS[0]
        );
      })
      .catch((err) => {
        loading.value = false;
        handleErrorMessage(err, 'Error loading AI Explorer data');
      })
      .finally(() => {
        if (currentProject) {
          getScenarioGroups(currentProject.project_id);
        }
      });
  }

  function handleErrorMessage(axiosError, $message) {
    if (axiosError.response.status === 429) {
      toast.unexpected(axiosError);
    } else {
      toast.error($message);
    }
    console.log(axiosError);
    throw axiosError;
  }

  function toggleShowAIExplorer() {
    showAIExplorer.value = !showAIExplorer.value;
    if (showAIExplorer.value) {
      currentUsername.value = auth.getUser()?.name ?? '';
    }
  }

  function changeMode(newMode: string) {
    clearCache();
    mode.value = newMode;
  }

  function closeAIExplorer() {
    showAIExplorer.value = false;
    mode.value = MODES.regular;
  }

  function getQueryTypes(): QueryType[] {
    return queryTypes;
  }

  function openWithLayerContext(layerNode) {
    showAIExplorer.value = true;
    showMapQueryWindow.value = true;
    mapsLayerNode.value = layerNode;
    mode.value = MODES.regular;
  }

  function setAIExplorerVisibility(visible: boolean) {
    showAIExplorer.value = visible;
  }

  function toggleAIExplorerWithScenarioContext() {
    mode.value = mode.value === MODES.scenario ? MODES.regular : MODES.scenario;
    showAIExplorer.value = !showAIExplorer.value;
    expectedOutputSet.value = MODES.scenario
      ? new Set(['sentence'])
      : new Set(['table']);
  }

  function toggleAIExplorerInAssistantMode() {
    mode.value =
      mode.value === MODES.assistant ? MODES.regular : MODES.assistant;
    showAIExplorer.value = !showAIExplorer.value;
    if (mode.value === MODES.assistant) {
      const lastClicked = JSON.parse(
        localStorage.getItem('ai-assistant-clicks') ?? '{}'
      );
      if (aiInsight.value.group_id) {
        lastClicked[aiInsight.value.group_id] = new Date().toUTCString();
        localStorage.setItem(
          'ai-assistant-clicks',
          JSON.stringify(lastClicked)
        );
      }
    }
  }

  function closeMapQueryWindow() {
    showMapQueryWindow.value = false;
    clearMapsContext();
  }

  return {
    setLegacyStore,
    showAIExplorer,
    showAIExplorerScenarioMode,
    showAIExplorerRegularMode,
    showAssistant,
    setMatrix,
    toggleShowAIExplorer,
    closeAIExplorer,
    loadAIExplorerData,
    currentContext,
    setCurrentThreadContext,
    getQueryTypes,
    threadBeingCreated,
    toggleThreadBeingCreated,
    getApps,
    apps,
    data,
    keywords,
    currentQuery,
    retryQuery,
    submitQuery,
    updateQueryStatus,
    addKeyword,
    addKeywordDirectly,
    clearCurrentKeywords,
    clearCurrentQuery,
    setFullQuery,
    keywordMatches,
    getKeyWordFromIndex,
    isLoadingKeywords,
    submitFeedback,
    currentKeywords,
    addToQuery,
    currentAvatar,
    setCurrentAIAvatarInThread,
    loading,
    clearKeywordMatches,
    preventScroll,
    refreshData,
    expectedOutputSet,
    addToExpectedOutput,
    removeFromExpectedOutput,
    clearExpectedOutput,
    clearCache,
    isSetCursorToEnd,
    shouldSuggestToUseKeywords,
    setActiveSubQueryId,
    activeSubQueryId,
    clearActiveSubQueryId,
    openWithLayerContext,
    toggleAIExplorerWithScenarioContext,
    showMapQueryWindow,
    mapsLayerNode,
    closeMapQueryWindow,
    mapsBuffer,
    mapsQueryArea,
    clearMapsContext,
    preloadedQueries,
    getQueryString,
    deleteThread,
    fetchAIAssistantShowStatus,
    showAIExplorerInAssistantMode,
    toggleAIExplorerInAssistantMode,
    changeMode,
    scenarioGroups,
    aiInsight,
    setAssistantVisibility,
    setAIExplorerVisibility,
    setLaunchedFrom,
  };
});
