// Static imports
import { createApp } from 'vue';
import './bootstrap';
import generalMixins from './mixins/general';
import scenarioMixins from './mixins/scenario';
import applicationRouter, { useRouteInterceptors } from '@/js/routes';
import store from '@/js/store';
import { createPinia, PiniaVuePlugin, setActivePinia } from 'pinia';
import App from '@/js/layouts/App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VTooltip, VClosePopover } from 'v-tooltip';
import BlinkingDots from '@/js/components/BlinkingDots.vue';
import ClickOutside from '@component-library/directives/click-outside';
import ClickAndContextMenu from '@component-library/directives/click-and-context-menu';
import Sticky from '@/js/modules/project/data-table/directives/sticky';
import '@component-library/EventBus';
import { Buffer } from 'buffer';
import ToastsStorePlugin from '@component-library/store/plugins/toasts-vue-adapter-plugin';
import createSentry from '@component-library/sentry';
import { initializeAnalytics } from '@component-library/analytics';
import { isProd } from '@component-library/utils/is-prod';
import { useAxiosInterceptors } from './axios-interceptors';
import { setRouter as setAppSwitcherRouter } from '@component-library/switcher/handover';
import { configureCompat } from '@vue/compat';
import { parseCachedAccess } from '@component-library/feature-manager';

// default everything to Vue 3 behavior, and only enable compat
// for certain features
configureCompat({
  MODE: 3,
  INSTANCE_SET: true,
  CUSTOM_DIR: true,
  OPTIONS_DESTROYED: true,

  // Required for LiquorTree
  INSTANCE_EVENT_EMITTER: true,
  INSTANCE_SCOPED_SLOTS: true,
});

if (globalThis.loadedVueApp === true) {
  // Cannot import this app.ts file.
  // It will cause the Vue app to be reinitialized.
  throw new Error('Vue app already loaded');
}
globalThis.loadedVueApp = true;

// Dynamic imports
if (
  import.meta.env.VITE_SENTRY_LARAVEL_DSN &&
  import.meta.env.VITE_SENTRY_LARAVEL_DSN != ''
) {
  createSentry();
}

// If explicitly enabled or not local environment
// Use the service worker.
if (
  import.meta.env.VITE_SERVICE_WORKER === 'true' ||
  import.meta.env.VITE_APP_ENV !== 'local'
) {
  if (window.location.search.includes('no-service-worker')) {
    console.log(
      'Service worker skipped due to query parameter: no-service-worker'
    );
  } else {
    console.log('Service worker enabled');
    import('./register-service-worker');
  }
}

// @ts-expect-error global is not declared
window.global = window;
// @ts-expect-error Buffer is not declared
window.Buffer = Buffer;

const pinia = createPinia();
setActivePinia(pinia);

useRouteInterceptors();

const app = createApp(App);

app.use(applicationRouter);
setAppSwitcherRouter(applicationRouter);
app.use(pinia);
app.use(store);

// Set Axios configuration
app.use(VueAxios, axios);
axios.defaults.baseURL = `/api`;

app.component('blinking-dots', BlinkingDots);
app.directive('click-outside', ClickOutside);
app.directive('context-and-click-menu', ClickAndContextMenu);
app.directive('sticky', Sticky);
app.directive('lazy', {
  mounted: lazyLoad,
  updated: lazyLoad,
});
app.directive('focus', {
  mounted(el) {
    el.focus();
  },
});

function lazyLoad(el) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target as HTMLImageElement;
        img.src = img.dataset.url || '';
        observer.disconnect();
      }
    });
  }, options);

  observer.observe(el);
}

//Set custom global methods
app.mixin(generalMixins);
app.mixin(scenarioMixins);

useAxiosInterceptors(store);

// Tooltips
VTooltip.options.defaultBoundariesElement = 'window';
VTooltip.options.autoHide = false;

app.directive('tooltip', VTooltip);
app.directive('close-popover', VClosePopover);
app.use(PiniaVuePlugin);

app.use(ToastsStorePlugin);

if (!isProd) {
  app.config.performance = true;
  console.warn('Non production build!');
}

parseCachedAccess();
initializeAnalytics();

app.mount('#app');

export default app;
export const router = applicationRouter;
